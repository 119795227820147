import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col } from "react-bootstrap"
import PostLinks from "../components/Blog/PostLinks"
import "../components/Blog/blog.css"

const PageTemplate = ({ data, pageContext }) => {
  const post = data.contentfulBlogPost
  const previous = pageContext.prev
  const next = pageContext.next

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="margin: 20px 0 20px 0; max-width: 100%" />`,
          }}
        />
      ),

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}"  /></a>`,
            }}
          />
        )
      },
    },
  }

  return (
    <Layout pageTitle={post.title}>
      <Container
        style={{
          marginTop: "2rem",
        }}
      >
        <Row>
          <Col>
            <img
              src={post.image?.fluid.src}
              alt={post.image?.title}
              style={{
                width: "100%",
              }}
            />
          </Col>
        </Row>
        <Row style={{
            justifyContent: 'center'
        }}>
          <Col className="blog-content">
            <div
              style={{
                marginTop: "2rem",
                // overflow: 'hidden'
              }}
            >
                <p>{post.updatedAt}</p>
              <h1 className="post-title">{post.title}</h1>
              <div style={{
                  borderBottom: '4px solid #7FAA74',
                  marginBottom: '2rem',
                  
              }} />
              <p className="blog-text">
              {documentToReactComponents(
                post.childContentfulBlogPostPostContentRichTextNode.json,
                options
              )}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <PostLinks previous={previous} next={next} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const getBlogPost = graphql`
  query ContentfulBlogPostBySlug($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      postExcerpt
      updatedAt(formatString: "dddd MMMM Do, YYYY")
      childContentfulBlogPostPostContentRichTextNode {
        json
      }
      image: featuredImage {
        file {
          url
        }
        fluid(maxWidth: 800) {
          sizes
          src
          srcSet
        }
      }
    }
  }
`

export default PageTemplate
